@import "./defines.scss";

@font-face {
	font-family: "72";
	font-style: normal;
	font-weight: 400;
	src: local("72"),
		url(https://ui5.sap.com/sdk/resources/sap/ui/core/themes/sap_fiori_3/fonts/72-Regular.woff2?ui5-webcomponents) format("woff2"),
		url(https://ui5.sap.com/sdk/resources/sap/ui/core/themes/sap_fiori_3/fonts/72-Regular.woff?ui5-webcomponents) format("woff");
}


body {
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #fff;
	color: rgb(58, 58, 58);
	position:relative; 
	min-height: 100vh;

	margin: 0;
	overflow: hidden;
	padding: 0;
	font-family: Raleway, sans-serif;
	font-weight: 500;
	overflow-y: auto;
	font-family: Raleway, sans-serif;
}

.hidden {
	display:none !important;
}

.invisible {
	visibility: hidden !important;
}

.wrapper {

	display: flex;
	flex-direction: row;
	flex-grow: 1;
	height: calc(100vh - 44px);

	.content {
		position:relative;
		flex-grow: 1;
	}

}

